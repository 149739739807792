import {
  Card,
  CardContent,
  CardHeader,
  Skeleton,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
  styled,
  type CardProps,
} from '@mui/material';

import OptionMenu from '@core/components/option-menu';
import type { OptionType } from '@core/components/option-menu/types';

import { Panels } from '@/components/panels/panels';
import { useEnvironment } from '@/contexts/useEnvironment';
import { useRoles } from '@/contexts/useRoles';
import { useWorkspace } from '@/contexts/useWorkspace';
import { PanelSize } from '@/contexts/workspaceContext';
import type { BackupVault } from '@/data/vaults/backupVault';

export interface BackupVaultCardProps {
  data?: BackupVault;
  loading?: boolean;
  style?: object;
  editable?: boolean;
  onChange?: () => void;
  onClick?: (vault: BackupVault) => void;
  clickable?: boolean;
}

interface VaultCardProps extends CardProps {
  clickable: boolean; // Add the custom 'clickable' prop
}

const CustomCard = ({ clickable, ...props }: VaultCardProps) => (
  <Card {...props}>{props.children}</Card>
);

const VaultCard = styled(CustomCard)(({ theme, clickable }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  textAlign: 'left',
  minWidth: '300px',
  maxWidth: '420px',
  minHeight: '175px',
  boxShadow: 'none',
  border: clickable ? '1px solid transparent' : 'none',
  borderRadius: '16px',
  '& .MuiTypography-root, & button i': {
    color: 'rgb(var(--mui-mainColorChannels-light) / 0.8);',
  },
  '& .MuiCardHeader-root': {
    height: '60px',
  },
  '& .MuiCardContent-root': {
    padding: '24px',
    paddingTop: 0,
  },
  cursor: clickable ? 'pointer' : 'default',
  '&:hover': {
    border: clickable ? '1px solid #727283' : 'none',
  },
}));

const VaultTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: 'none',
  color: theme.palette.text.secondary,
  padding: '2px 10px 2px 0px',
}));

const VaultTablePropCell = styled(VaultTableCell)(() => {
  return {
    minWidth: '120px',
  };
});

export default function BackupVaultCard(props: BackupVaultCardProps) {
  const { isAuthorized } = useRoles();
  const { rightPanel } = useWorkspace();
  const { setIsOpen, setComponent } = rightPanel;
  const { isDev, isDemo } = useEnvironment();
  function editVault() {
    if (!props.data) {
      return;
    }

    setComponent({
      panel: Panels.EditBackupVault,
      props: {
        entity: props.data,
        onChange: () => {
          props.onChange?.();
        },
      },
    });
    setIsOpen(true, PanelSize.Small);
  }

  return (
    <VaultCard
      clickable={props.onClick !== undefined}
      onClick={() => props.onClick?.(props.data!)}
      data-testid='backup-vault-card'
      style={{
        backgroundColor: props?.data?.backgroundColor,
        ...props.style,
      }}
    >
      {props.loading && (
        <>
          <CardHeader
            component={Skeleton}
            sx={{ marginLeft: '20px', paddingTop: '20px' }}
          ></CardHeader>
          <CardContent>
            <table className='w-full table-fixed'>
              <tbody>
                {[...Array(4)].map((_, index) => (
                  <tr key={index}>
                    <td className='px-[5px] pt-[5px] pl-0'>
                      <Skeleton />
                    </td>
                    <td className='pl-[5px] pt-[5px] pr-0'>
                      <Skeleton />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </CardContent>
        </>
      )}
      {!props.loading && props.data && (
        <>
          <CardHeader
            sx={{ paddingBottom: '5px' }}
            title={props.data.name}
            titleTypographyProps={{ variant: 'h6' }}
            action={
              props.editable !== false &&
              isAuthorized('update:vaults') && (
                <OptionMenu
                  icon='material-symbols-settings-outline'
                  options={[...(isAuthorized('update:vaults') ? ['Edit'] : [])]}
                  onOptionSelected={(option: OptionType): void => {
                    if (!props.data) return;
                    switch (option) {
                      case 'Edit':
                        editVault();
                        break;
                    }
                  }}
                />
              )
            }
          />
          <CardContent>
            <TableContainer>
              <Table className='table-fixed'>
                <tbody>
                  <TableRow>
                    <VaultTablePropCell>
                      <Typography variant='body1'>Cloud Provider</Typography>
                    </VaultTablePropCell>
                    <VaultTableCell>
                      <Typography variant='body2'>
                        {props.data.cloudProvider}
                      </Typography>
                    </VaultTableCell>
                  </TableRow>
                  <TableRow>
                    <VaultTablePropCell>
                      <Typography variant='body1'>Account ID</Typography>
                    </VaultTablePropCell>
                    <VaultTableCell>
                      <Tooltip title={props.data.cloudAccountId}>
                        <Typography
                          variant='body2'
                          sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {props.data.cloudAccountId}
                        </Typography>
                      </Tooltip>
                    </VaultTableCell>
                  </TableRow>
                  <TableRow>
                    <VaultTablePropCell>
                      <Typography variant='body1'>Region</Typography>
                    </VaultTablePropCell>
                    <VaultTableCell>
                      <Typography variant='body2'>
                        {props.data.region}
                      </Typography>
                    </VaultTableCell>
                  </TableRow>
                  {(isDev || isDemo) && props.data.cloudProvider === 'AWS' && (
                    <TableRow>
                      <VaultTablePropCell>
                        <Typography variant='body1'>Object Lock</Typography>
                      </VaultTablePropCell>
                      <VaultTableCell>
                        <Typography variant='body2'>
                          {props.data.objectLock ? '✓' : '✗'}
                        </Typography>
                      </VaultTableCell>
                    </TableRow>
                  )}
                </tbody>
              </Table>
            </TableContainer>
          </CardContent>
        </>
      )}
    </VaultCard>
  );
}
