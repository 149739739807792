'use client';

import {
  ClickAwayListener,
  Fade,
  Paper,
  Popper,
  Box,
  Divider,
  IconButton,
  MenuItem,
  MenuList,
  type PopperPlacementType,
  Typography,
} from '@mui/material';
import classnames from 'classnames';
import Link from 'next/link';
import type { ReactNode, RefObject, SyntheticEvent } from 'react';
import { createRef, useCallback, useRef, useState } from 'react';

import type { OptionsMenuType, OptionType, OptionMenuItemType } from './types';

const MenuItemWrapper = ({
  children,
  option,
}: {
  children: ReactNode;
  option: OptionMenuItemType;
}) => {
  if (option.href) {
    return (
      <Box component={Link} href={option.href} {...option.linkProps}>
        {children}
      </Box>
    );
  } else {
    return <>{children}</>;
  }
};

const InnerMenu = (
  props: OptionsMenuType & {
    open: boolean;
    placement: PopperPlacementType;
    closeMenu: () => void;
    anchorRef: React.RefObject<HTMLElement>;
  }
) => {
  const { onOptionSelected, closeMenu, open, options, anchorRef, placement } =
    props;
  const innerAnchorsRef = useRef<RefObject<HTMLLIElement>[]>([]);
  innerAnchorsRef.current = options.map(
    (_, i) => innerAnchorsRef.current[i] ?? createRef<HTMLLIElement[]>()
  );
  const [subMenuOpen, setSubMenuOpen] = useState<number>();
  const handleClose = useCallback(
    (event: Event | SyntheticEvent) => {
      if (anchorRef.current?.contains(event.target as HTMLElement)) {
        return;
      }
      setSubMenuOpen(undefined);
      closeMenu();
    },
    [anchorRef, closeMenu]
  );
  const handleSelect = useCallback(
    (option: OptionType, e: Event | SyntheticEvent) => {
      if (onOptionSelected) onOptionSelected(option);
      handleClose(e);
    },
    [onOptionSelected, handleClose]
  );

  return (
    <Popper
      open={open}
      anchorEl={anchorRef.current}
      placement={placement}
      transition
      disablePortal
      sx={{ zIndex: 5 }}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps}>
          <Paper className={'shadow-lg'}>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList autoFocusItem={open}>
                {options.map((option: OptionType, index: number) => {
                  if (typeof option === 'string') {
                    return (
                      <MenuItem
                        key={index}
                        data-testid={option}
                        sx={{ minWidth: '150px' }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSelect(option, e);
                        }}
                      >
                        {option}
                      </MenuItem>
                    );
                  } else if ('divider' in option) {
                    return (
                      option.divider && (
                        <Divider key={index} {...option.dividerProps} />
                      )
                    );
                  } else if ('groupHeader' in option) {
                    return (
                      <Typography
                        key={index}
                        variant='subtitle1'
                        className='px-[24px] py-[4px] my-[12px] w-full cursor-default'
                        sx={{
                          lineHeight: '24px',
                          backgroundColor:
                            'var(--mui-palette-background-tableHeader)',
                          textTransform: 'uppercase',
                        }}
                      >
                        {option.text}
                      </Typography>
                    );
                  } else {
                    return (
                      <MenuItem
                        key={index}
                        ref={innerAnchorsRef.current[index]}
                        {...option.menuItemProps}
                        {...(option.href && { className: 'p-0' })}
                        onMouseEnter={(e) => {
                          setSubMenuOpen(index);
                          option.menuItemProps?.onMouseEnter?.(e);
                        }}
                        onMouseLeave={(e) => {
                          setSubMenuOpen(undefined);
                          option.menuItemProps?.onMouseLeave?.(e);
                        }}
                        onClick={(e) => {
                          handleSelect(option, e);
                          option.menuItemProps?.onClick?.(e);
                        }}
                      >
                        <MenuItemWrapper option={option}>
                          {(typeof option.icon === 'string' ? (
                            <i className={option.icon} />
                          ) : (
                            option.icon
                          )) || null}
                          {option.text}
                        </MenuItemWrapper>
                        {option.subMenu && (
                          <InnerMenu
                            {...props}
                            anchorRef={innerAnchorsRef.current[index]}
                            options={option.subMenu}
                            placement='right-start'
                            open={subMenuOpen === index}
                            closeMenu={() => setSubMenuOpen(undefined)}
                          />
                        )}
                      </MenuItem>
                    );
                  }
                })}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
};

const OptionMenu = (props: OptionsMenuType) => {
  const { icon, iconClassName, iconButtonProps, onToggle, Component } = props;
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const handleToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setOpen((prevOpen) => !prevOpen);
    onToggle?.(!open);
  };

  return (
    <>
      {Component && (
        <Component
          data-testid='option-menu-button'
          ref={anchorRef}
          onClick={handleToggle}
        />
      )}
      {!Component && (
        <IconButton
          ref={anchorRef}
          size='small'
          data-testid='option-menu-button'
          onClick={handleToggle}
          {...iconButtonProps}
        >
          {typeof icon === 'string' ? (
            <i className={classnames(icon, iconClassName)} />
          ) : (icon as ReactNode) ? (
            icon
          ) : (
            <i
              className={classnames(
                'material-symbols-more-horiz',
                iconClassName
              )}
            />
          )}
        </IconButton>
      )}
      <InnerMenu
        {...props}
        placement={props.leftAlignMenu ? 'bottom-start' : 'bottom-end'}
        open={open}
        closeMenu={() => {
          setOpen(false);
          onToggle?.(false);
        }}
        anchorRef={anchorRef}
      />
    </>
  );
};

export default OptionMenu;
